export const speiseplan = {    
    periode:  "31.03. - 04.04.2025",
    
    zusatz:  "11:30 Uhr - 13:30 Uhr",

    montag: {
        sichtbar: true,
        gericht: "Grüne Bohnensuppe",
        beigabe: "",
        preis: "7,00 €"
    },

    dienstag: {
        sichtbar: true,
        gericht: "Nudeln mit Tomatensoße",
        beigabe: "Jägerschnitzel",
        preis: "8,50 €"
    },


    mittwoch: {
        sichtbar: true,
        gericht: "Hähnchenkeule",
        beigabe: "mit Kartoffelklößen und Gurkensalat",                                   
        preis: "10,50 €"
    },

    donnerstag: {
        sichtbar: true,
        gericht: "Bratwurst",
        beigabe: "mit Rosenkohl und Kartoffeln",
        preis: "9,90 €"
    },

    freitag: {
        sichtbar: true,
        gericht: "Tagessuppe",
        beigabe: "",
        preis: "7,00 €"
    },
};
